import React, { useState, useEffect, forwardRef } from 'react';
// import { useReactToPrint } from 'react-to-print';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

//gambar
import PrintIcon from '@mui/icons-material/Print';
import BorderAllIcon from '@mui/icons-material/BorderAll';

function Lap_Penjualan(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [periode1, setPeriode1] = useState(new Date());
    const [periode2, setPeriode2] = useState(new Date());
    const [periode3, setPeriode3] = useState(new Date());
    const [periode4, setPeriode4] = useState(new Date());
    const [data_pengguna, setDataPengguna] = useState([]);
    const [pengguna, setPengguna] = useState("");
    // const [data_metode_bayar, setDataMetodeBayar] = useState([]);
    const [metode_bayar, setMetodeBayar] = useState("");

    // const componentRef = useRef();

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/laporan/tampil_pengguna.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPengguna(data.data);
                }
                else {
                    setDataPengguna([]);
                }
            })
            .catch(error => {
                setDataPengguna([]);
            }
            );

        // fetch(props.aplikasi + '/metode_bayar/tampil_metode_bayar.php',
        //     {
        //         method: 'POST',
        //         body: JSON.stringify
        //             ({
        //                 status: "ada"
        //             }),
        //         headers:
        //         {
        //             "Content-type": "application/json; charset=UTF-8"
        //         }
        //     })
        //     .then(response => response.json())
        //     .then(data => {
        //         if (data.kode === 1) {
        //             setDataMetodeBayar(data.data);
        //         }
        //         else {
        //             setDataMetodeBayar([]);
        //         }
        //     })
        //     .catch(error => {
        //         setDataMetodeBayar([]);
        //     }
        //     );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const cetak = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/cetak_penjualan.php?permintaan=cetak&periode1=' + format_periode1 + '&periode2=' + format_periode2 + '&id_pengguna=' + pengguna + '&metode=' + metode_bayar);
    }

    const cetak_rangkuman = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/cetak_penjualan_rangkum.php?periode1=' + format_periode1 + '&periode2=' + format_periode2 + '&id_pengguna=' + pengguna + '&metode=' + metode_bayar);
    }

    const excel_rangkuman = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/excel_penjualan_rangkum.php?periode1=' + format_periode1 + '&periode2=' + format_periode2 + '&id_pengguna=' + pengguna + '&metode=' + metode_bayar);
    }

    const cetak_keuntungan = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/cetak_penjualan.php?permintaan=cetak_keuntungan&periode1=' + format_periode1 + '&periode2=' + format_periode2 + '&id_pengguna=' + pengguna + '&metode=' + metode_bayar);
    }

    const cetak_rekap = () => {
        if (periode3 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode4 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode3.getFullYear());
        var bulan = String(periode3.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode3.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode3 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode4.getFullYear());
        bulan = String(periode4.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode4.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode4 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/cetak_penjualan_rekap.php?permintaan=cetak&periode1=' + format_periode3 + '&periode2=' + format_periode4);
    }

    const cetak_keuntungan_rekap = () => {
        if (periode3 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode4 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode3.getFullYear());
        var bulan = String(periode3.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode3.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode3 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode4.getFullYear());
        bulan = String(periode4.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode4.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode4 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/cetak_penjualan_rekap.php?permintaan=cetak_keuntungan&periode1=' + format_periode3 + '&periode2=' + format_periode4);
    }

    // const coba = useReactToPrint({
    //     content: () => componentRef.current,
    // });

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Tanggal Transaksi
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode1}
                                        onChange={(newValue) => {
                                            setPeriode1(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                    s/d
                                </Typography>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode2}
                                        onChange={(newValue) => {
                                            setPeriode2(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Pegawai
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        id="pengguna"
                                        variant="outlined"
                                        fullWidth
                                        value={pengguna}
                                        onChange={(event) => setPengguna(event.target.value)}
                                    >
                                        <MenuItem key={""} value={""}>Seluruh Pengguna</MenuItem>
                                        {data_pengguna.map((data_pengguna) => {
                                            return (
                                                <MenuItem key={data_pengguna.id_pengguna} value={data_pengguna.id_pengguna}>{data_pengguna.nama}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Metode Bayar
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        id="metode_bayar"
                                        variant="outlined"
                                        fullWidth
                                        value={metode_bayar}
                                        onChange={(event) => setMetodeBayar(event.target.value)}
                                    >
                                        <MenuItem value={""}>Seluruh Metode</MenuItem>
                                        <MenuItem value={"TUNAI"}>Tunai</MenuItem>
                                        <MenuItem value={"NON TUNAI"}>Non Tunai</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak (Tanpa Keuntungan)</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_keuntungan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak (Dengan Keuntungan)</Typography>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_rangkuman()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak Rangkuman</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel_rangkuman()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel Rangkuman</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Tanggal Transaksi
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode3}
                                        onChange={(newValue) => {
                                            setPeriode3(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                    s/d
                                </Typography>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode4}
                                        onChange={(newValue) => {
                                            setPeriode4(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_rekap()}
                                >
                                    <Typography variant="body2" noWrap={true}>Rekap (Tanpa Keuntungan)</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_keuntungan_rekap()}
                                >
                                    <Typography variant="body2" noWrap={true}>Rekap (Dengan Keuntungan)</Typography>
                                </Button>

                                {/* <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => coba()}
                                >
                                    <Typography variant="body2" noWrap={true}>Coba</Typography>
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                {/* <div
                    style={{
                        display: "none"
                    }}
                >
                    <Cetak
                        ref={componentRef}
                        basename={props.basename}
                        bpjs={props.bpjs}
                        norm={norm}
                        sep={bpjs_sep}
                        tgl_sep={format_tanggal(bpjs_sep_tgl)}
                        no_kartu={bpjs}
                        nama={nama}
                        tgl_lahir={format_tanggal(tgl_lahir)}
                        jk={jk}
                        poli={nmbagian}
                        faskes={bpjs_perujuk}
                        diagnosa={bpjs_diagnosa + "-" + bpjs_diagnosa_nama}
                        catatan={catatan}
                        no_antri={no_antri}
                        prb={bpjs_prb}
                        peserta={bpjs_jenis_peserta}
                        cetakan={"Cetakan ke 1 " + format_tangal_waktu(tgl_cetak) + ", No. / Tgl. Rujukan " + bpjs_no_kunjungan + " / " + format_tanggal(bpjs_sep_tgl) + ", No. Registrasi : " + noreg + ", ASEP V3 RSUD Bendan"}
                    />
                </div> */}
            </Box>
        </div>
    );
}

export default forwardRef(Lap_Penjualan);