import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//gambar
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Piutang(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_blokir, setTampilBlokir] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [data_piutang, setDataPiutang] = useState([]);
    const [data_metode_bayar, setDataMetodeBayar] = useState([]);
    const [id_penjualan, setIdPenjualan] = useState("");
    const [nama, setNama] = useState("");
    const [hutang_awal, setHutangAwal] = useState(0);
    const [angsuran, setAngsuran] = useState(0);
    const [kasir, setKasir] = useState("");
    const [waktu_pembayaran, setWaktuPembayaran] = useState("");
    const [data_angsuran, setDataAngsuran] = useState([]);
    const [metode_bayar, setMetodeBayar] = useState("1");
    const [jumlah_bayar, setJumlahBayar] = useState(0);
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [tampil_dialog_bayar, setTampilDialogBayar] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_piutang.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPiutang(data.data);
                }
                else {
                    setDataPiutang([]);
                }
            })
            .catch(error => {
                setDataPiutang([]);
            }
            );

        fetch(props.aplikasi + '/kasir/tampil_metode_bayar.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                    setTampilBlokir(true);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
                setTampilBlokir(true);
            }
            );


        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/kasir/tampil_piutang.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataPiutang(data.data);
                    }
                    else {
                        setDataPiutang([]);
                    }
                })
                .catch(error => {
                    setDataPiutang([]);
                }
                );
        }, 10000);

        return () => {
            clearInterval(auto_refresh);
        };

    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_piutang = () => {
        fetch(props.aplikasi + '/kasir/tampil_piutang.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPiutang(data.data);
                    let awal = 1;
                    let akhir = data.data.length;
                    while (awal <= akhir) {
                        if (data.data[awal - 1].id_penjualan === id_penjualan) {
                            setAngsuran(data.data[awal - 1].angsuran);
                            break;
                        }
                        awal++;
                    }
                }
                else {
                    setDataPiutang([]);
                }
            })
            .catch(error => {
                setDataPiutang([]);
            }
            );
    }

    const ubah_jumlah_bayar = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar(event.target.value);
    }

    const perbaiki_jumlah_bayar = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar(event.target.value);
        }
        else {
            setJumlahBayar(0);
        }
    }

    const simpan = () => {
        if (parseFloat(hutang_awal) - parseFloat(angsuran) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Hutang sudah dilunasi");
            setTampilNotif(true);
            return;
        }

        if (metode_bayar.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        if (metode_bayar.trim() === "99") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar ini tidak dapat digunakan");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        if (parseFloat(jumlah_bayar) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        let tampung_jumlah_bayar = 0;
        if (jumlah_bayar > parseFloat(hutang_awal) - parseFloat(angsuran)) {
            tampung_jumlah_bayar = parseFloat(hutang_awal) - parseFloat(angsuran);
        }
        else {
            tampung_jumlah_bayar = jumlah_bayar;
        }

        setProses(true);
        fetch(props.aplikasi + '/kasir/simpan_piutang.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_penjualan: id_penjualan,
                        id_metode_bayar: metode_bayar,
                        jumlah_bayar: tampung_jumlah_bayar,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    if (jumlah_bayar >= parseFloat(hutang_awal) - parseFloat(angsuran)) {
                        setAngsuran(hutang_awal);
                    }
                    else {
                        tampil_piutang();
                    }

                    fetch(props.aplikasi + '/kasir/tampil_angsuran.php',
                        {
                            method: 'POST',
                            body: JSON.stringify
                                ({
                                    id_penjualan: id_penjualan
                                }),
                            headers:
                            {
                                "Content-type": "application/json; charset=UTF-8"
                            }
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data.kode === 1) {
                                setDataAngsuran(data.data);
                            }
                            else {
                                setDataAngsuran([]);
                            }
                        })
                        .catch(error => {
                            setDataAngsuran([]);
                        }
                        );

                    setTampilDialogBayar(false);
                    setTampilDialog(true);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={tampil_blokir}
            >
                <Typography variant="h6" color="white">
                    Halaman Gagal Dimuat Dengan Sempurna. Silakan Reload Atau Tekan F5
                </Typography>
            </Backdrop>

            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_piutang.map((data_piutang) => {
                        return (
                            <Grid
                                item xs={6} sm={4} md={3} lg={2}
                                key={data_piutang.id_penjualan}
                                onClick={() => {
                                    setIdPenjualan(data_piutang.id_penjualan);
                                    setNama(data_piutang.nama_anggota);
                                    setWaktuPembayaran(data_piutang.waktu_transaksi);
                                    setHutangAwal(data_piutang.hutang_awal);
                                    setAngsuran(data_piutang.angsuran);
                                    setKasir(data_piutang.kasir);
                                    fetch(props.aplikasi + '/kasir/tampil_angsuran.php',
                                        {
                                            method: 'POST',
                                            body: JSON.stringify
                                                ({
                                                    id_penjualan: data_piutang.id_penjualan
                                                }),
                                            headers:
                                            {
                                                "Content-type": "application/json; charset=UTF-8"
                                            }
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.kode === 1) {
                                                setDataAngsuran(data.data);
                                            }
                                            else {
                                                setDataAngsuran([]);
                                            }
                                        })
                                        .catch(error => {
                                            setDataAngsuran([]);
                                        }
                                        );

                                    setTampilDialog(true);
                                }}
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{data_piutang.nama_anggota}</Typography>}
                                                secondary={
                                                    <div>
                                                        <Typography noWrap variant="body2" color="white">{data_piutang.id_penjualan}</Typography>
                                                        <Typography noWrap variant="body2" color="white">Rp. {format_rupiah(parseFloat(data_piutang.hutang_awal) - parseFloat(data_piutang.angsuran))}</Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                fullScreen
                open={tampil_dialog}
            >
                <Box>
                    <AppBar
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#35b2b8" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Piutang
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ marginTop: "64px", padding: "10px" }}>
                        <Card sx={{ padding: "10px", marginBottom: "10px", }}>
                            <Typography variant="h6">
                                Data Penjualan
                            </Typography>
                            <Divider />
                            <br />

                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                No. Bukti
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {id_penjualan}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Nama
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {nama}
                                            </Typography>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Waktu Pembelian
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {waktu_pembayaran}
                                            </Typography>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Kasir
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {kasir}
                                            </Typography>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Hutang Awal
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                Rp. {format_rupiah(parseFloat(hutang_awal))}
                                            </Typography>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Sudah Diangsur
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                Rp. {format_rupiah(parseFloat(angsuran))}
                                            </Typography>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Sisa Hutang
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                Rp. {format_rupiah(parseFloat(hutang_awal) - parseFloat(angsuran))}
                                            </Typography>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AttachMoneyIcon />}
                                                sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em" }}
                                                onClick={() => {
                                                    setJumlahBayar(0);
                                                    setTampilDialogBayar(true)
                                                }}
                                            >
                                                <Typography component={"span"} variant="h6" noWrap={true}>Bayar</Typography>
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>

                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h6">
                                Riwayat Angsuran
                            </Typography>
                            <Divider />
                            <br />

                            <TableContainer component={Paper}>
                                <Table stickyHeader size="small" aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Tanggal</StyledTableCell>
                                            <StyledTableCell align="center">Metode Bayar</StyledTableCell>
                                            <StyledTableCell align="center">Kasir</StyledTableCell>
                                            <StyledTableCell align="center">Jumlah</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data_angsuran.map((tampung) => {
                                            return (
                                                <StyledTableRow key={tampung.id_angsuran}>
                                                    <TableCell size="small" align="center"><Typography variant="body2" noWrap={true}>{tampung.waktu_transaksi}</Typography></TableCell>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung.metode_bayar}</Typography></TableCell>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung.kasir}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(tampung.jumlah_bayar))}</Typography></TableCell>
                                                </StyledTableRow>
                                            );
                                        })}

                                        <TableRow>
                                            <TableCell align="right" colSpan={3}>
                                                <Typography variant="h6">
                                                    Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(parseFloat(angsuran))}</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={tampil_dialog_bayar}
                maxWidth="md"
            >
                <DialogTitle>Pembayaran</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" align="right">
                                            Tagihan
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(hutang_awal) - parseFloat(angsuran))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Typography variant="body2">
                                        Metode Bayar
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="metode_bayar"
                                                variant="outlined"
                                                fullWidth
                                                value={metode_bayar}
                                                onChange={(event) => {
                                                    setMetodeBayar(event.target.value);
                                                }}
                                            >
                                                {data_metode_bayar.map((data_metode_bayar) => {
                                                    return (
                                                        <MenuItem key={data_metode_bayar.id_metode} value={data_metode_bayar.id_metode}>{data_metode_bayar.nama}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="jumlah_bayar"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            disabled={metode_bayar === "M0" ? true : false}
                                            value={jumlah_bayar}
                                            onChange={(e) => ubah_jumlah_bayar(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" align="right">
                                            Kembalian
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar) - (parseFloat(hutang_awal) - parseFloat(angsuran)))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        variant="contained"
                        color="primary"
                        startIcon={<AttachMoneyIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >
                        Bayar
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        variant="contained"
                        color="primary"
                        startIcon={<CloseIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => {
                            setTampilDialogBayar(false);
                        }}
                    >
                        Tutup
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Piutang);